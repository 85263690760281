import React from 'react';
import { Card } from 'react-bootstrap';

function NFTCard({ collectionAddress, tokenId, name, imageURL }) {
  return (
    <Card className="mb-4">
      <Card.Img variant="top" src={imageURL} />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>Collection Address: {collectionAddress}</Card.Text>
        <Card.Text>Token ID: {tokenId}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default NFTCard;
