import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Nav, Spinner, Table } from 'react-bootstrap';
import { BrowserProvider, Contract } from 'ethers';
import SETTINGS from '../SETTINGS';
import CollectionABI from '../abis/CollectionABI.json'; // Import Collection ABI
import NodesABI from '../abis/NodesABI.json'; // Import Nodes ABI
import NFTCard from '../components/NFTCard';


const oldContractABI = [
    {
      "constant": true,
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [
        {
          "name": "_index",
          "type": "uint256"
        }
      ],
      "name": "tokenByIndex",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [
        {
          "name": "_tokenId",
          "type": "uint256"
        }
      ],
      "name": "mintTime",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [
        {
          "name": "_tokenId",
          "type": "uint256"
        }
      ],
      "name": "lastClaim",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
        "constant": true,
        "inputs": [
          {
            "name": "",
            "type": "address"
          }
        ],
        "name": "invitePercent",
        "outputs": [
          {
            "name": "",
            "type": "uint256"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [
          {
            "name": "",
            "type": "string"
          }
        ],
        "name": "inviteCode",
        "outputs": [
          {
            "name": "",
            "type": "address"
          }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
  ];

function Admin({ provider, address }) {
  const [activeTab, setActiveTab] = useState('addReferral');
  const [referralCode, setReferralCode] = useState('');
  const [referralAddress, setReferralAddress] = useState('');
  const [referralPercent, setReferralPercent] = useState('');
  const [nfts, setNfts] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [holders, setHolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportedData, setExportedData] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [referralData, setReferralData] = useState([]);


  const addressesToCheck = ["0x551EdD56609e7a1f8243993fAc321e33AaF140b4",
    "0xFF676ea6180f15C5eD5Ace5c35e800A432959f56",
    "0x9748a170531B9cE08c6302C133FF8760816c8EfA",
    "0x49DD2a7E85e4486e2DB59C6Aa662E7e9c227691C",
    "0xeA3fBEe72EB9E6d8350Bf9b37FFE20C06bF7C49f",
    "0x511C645389eBe73aecFfaA57924d14ec46c13de8",
    "0x22E7742c913aFC58B26D631e2194Ba44d628E8f7",
    "0x6552679f5D35Cf214F2eEf52379D8A789d42Dbea",
    "0xc54eB4EE194c60520Da558B54C659593f3b05FF5",
    "0xc380f8AB22fD74d3dC6D8B25B5d30AEdA6934915",
    "0xC627E1C3e0E585a9A3350f3962c6a04954Bc5108",
    "0x0E670BbfFc7ead71e4eb05DFe77016729B6b7C0E",
    "0x13Eb3d960d61dE4A5c7888827ccE5C29D021b20C",
    "0xE7921F856fdF5485B7C141B0C7C79d9d0133eaE7",
    "0xB72b81E750B2ef736ed7d49374D48c4a43e7529d",
    "0x007094658D5B370c60491A29639272559D04A4C8",
    "0xf6879fA93B363162ff57Cd8ed9b1D339af058eF6",
    "0x08e4f09523Ae1AE5d552bb85abCD2728573153B6",
    "0x39067D5149d36581e5B76E0169D6D357a12a51F5",
    "0xb96c0E127850B88144AF169966f2BF175D091816",
    "0xD25a9B86FC7Dd9ea5f80C6758a70a0B5CFF8F0C6",
    "0x542407B48C857cAc5D44680995D532D18685d5cc",
    "0x32853fEf935A2074B6E6942bd8f1fA994CE907e6",
    "0x7e9a503c200468f9A3E85C99d15b269B2C79339E",
    "0xd2835f467EF52d748a100d0F120F60A9E0aAd797",
    "0x83ee35639270BB981579b8E34fcD3F07C6A3e357",
    "0x2A97D73E58bf9eC36369a70EDB891a2940582EAb",
    "0x99b044Ab4438997d82C4B5589270665A95a8e917",
    "0xC67BAC94B7532B5B4F604d27F95a095a2eFBee60",
    "0xD06ef5F40451CCB00F841ba3BbFF39fe7a108e93",
    "0xb1cd80D9f55C359eF359d020c94e4420535a1057",
    "0xec610D5A92b15ee88371Ed226a22996D7b992EfB",
    "0x5DDC03f3f13839D508038a9A2429d76706e7fD69",
    "0x66331934ace6fB4e0D71B98F694D1fE547FAA902",
    "0x2cbF9ae43dD737919a3922Dd094C08A00B258021",
    "0x46ebC36fCAbAC790CfA5C6729f17533686627704",
    "0x49C64de99A5EC4647F9DdC249f27F1526DA784F9",
    "0x6014EA3b930Cd0cD2b8131A55a2b17f1229110B1",
    "0xa89119C4D16C4A1cb9e202fDF354C1e708EE5f23",
    "0x2B69Eb2124Cb68162672c39e7909BB5C0313B922",
    "0x6De17A91f10646D3E6cdEec712f5272eC783c866",
    "0x9959bE121F4159E76eEf60ab9b854e423B8D707A",
    "0x3D50d9bBB84e72A75ff8ab2b2360be904Ec0B5d2",
    "0x3344aBb06afd1dBa592e833ADD613E9941888F25",
    "0xc4fEF763ab1916c983B57448D3d7213624c018C4",
    "0xA198D5864251adE714d998837b3Bf1b580dDa2e8",
    "0xBf47078055A00Ae4b07445c6B7b2dBd965041E55",
    "0x030c7Ae2Fb9D81519a042FC621768a75c58Bb50E",
    "0x3c8d8F1AdbDE4F7711c9af33A803f6506dBD6EDB",
    "0xCC7F7566767E3da00Cf66d18034605e6CFa944DF",
    "0x02cf20562ee8BAc5ebb9520CF13E0198bE7703CD",
    "0x5B9c55bdd93241C4b4019d30aC9f65a4b44C23dC",
    "0x20963aeB86FEd6691524140d08bDbC5a9650B1dB",
    "0xE94FfBcCD16b9f6ce6819f78Cc2AF43516c1d855",
    "0x3a754d324a05223D6F5F3301A88F84513A5B63De",
    "0x1f824b82463Bc3aD252206c2a2034D8f37d5E760",
    "0x5488ea01962EAAB5872C87DA5b9622A7Ab7D7C72",
    "0x6e498077680D1AE71B7295C6ceb0e1e8721125B7",
    "0x41b7E4DCE21ea7db3f7705FF4d94C596294e5FD1",
    "0x7692b6E4f61E84E45Dd25f8B7Cb282B342B8EEdE",
    "0xa9C47a88833d93d76B5B980F3C8A9157A9498a5d",
    "0xdeb545033c408BF0AE767d22098762fa97DC1F61",
    "0xF664a2fF97F83715b608BaF796B4B2cFBf9690e5",
    "0xE9A169bEA6c93659FAdf2CdDfDc4659c7a395194",
    "0x8DEfB9B74490B3aABE7F7012E8B8a79D1B5d2179",
    "0xC799c2e33f8817562291c72F8a7bba865b346a09",
    "0x3ca7a9BbA4DCD9a511F6D9040F631Fbb03c2e153",
    "0xE20743eaD26d5B3bb471d08c1c45f48B1C34ebBD",
    "0x5D3465f780ceb1C2314dEC931b7018A2e27B4E43",
    "0x3e2Fb6Ecbb0cF9256833d9C297ab19C0058770C0",
    "0x6427400fA20DEF69c63639a44302BdF23F5EAB12",
    "0x78446DEB325a0A6D24918373aD472dB21149585D",
    "0x656Fe2d5b7c07596d88C6af6e62E5272D3812AB9",
    "0x1716a39d3B6b25f18070680E4e163d08DC6F3Fb4",
    "0x08eA1DEE6A6173d530a55531740e687Bc48C7c76",
    "0x772D720D8f5717e2Ac731CFb49dE2f7e82c86AB7",
    "0x25D852Fa0bB2A842468AA7c9ef87B65850c6302e",
    "0xD0CD34b27A5d2D4E00C298bC3D6c06F7176eabBE",
    "0x460fD7fE5b939fd8BdBbe831380f3E378663183D",
    "0xE5a4EeDF99Efa7880C87075a0B1Aaa378Cc8b206",
    "0x8d9c249DCA52f24024177BD389bc744Aa42300fc",
    "0x7E5916b06A467bA986C6B20e0E59803915fc5851",
    "0x8276790d1560D455e4f955927A98C8Eb7e114DbD",
    "0x035A46392Ad54493AEaFCa9E32Ee173f157DA4E1",
    "0xF7aD8D7B80b6806C8ebb5e5d8EBac4f7bd7Ac067",
    "0xd6E4694B67a2847cfB4B0D9447F8aaE0F1eb7042",
    "0xCE3dbaA5b5836ac39E977841DDc85D6f32995c86",
    "0xcCE6A0801ce006564D5A4B10673FB4ca64fed4dD",
    "0xb4b7C10F7eA51917fF192472956C006d98D2d87A",
    "0x01538b6df6d883913c123DaA50C3F26d18295876",
    "0xFCB247023BC4179A01EBd878b029Aa19B6D7aDdc",
    "0x7067F70f28c2cd6abBB16b492872Fc8E8c9Ae1EF",
    "0x3454262ec4C4a6B90CdfCd7235bFA49F43aa3Eb7",
    "0x03575fd2089cfd5b1f284c02b157fFDC9DaadEb2",
    "0x0032816CBab7ea0Fe57db9052F07430fc59B2Ca4",
    "0xE04CbF593C306208e774c81dc466a53C5640245b",
    "0xfa4eE764B7F4d564840974957d8f38249658F01F",
    "0x747c78F46c90dAf860D6C093E669B10A7A52BC0a",
    "0x41F3Ab9C9C62BFAA00b629B30Cc13d28A0FA0A6F",
    "0xd78824b40FD786291e7FCcb025f8f0F288bB566a",
    "0x2751Dd3716F13Be8c8Df134139b925112D915a65",
    "0x9b2F07628d8Bfd5e81a5d7E636920a5f4CD622a4",
    "0xE9EBb92a9777378112Ac0e17f945F06EC21810Db",
    "0xC4A3f0fa497E5cC4Ca55CB3Cf83BD15D81C13709",
    "0xDe16a174f13269aaFA98d675Bb3b6BC774aAE906",
    "0xf7eEFF2eB0F1e633C746B84f136F2f5Aa14101Cc",
    "0x4Dbd6733b0a98a4a69b0dEdf470A47CDA443ea41",
    "0x1bDC03dfE47E9D89Ef902ba6E9668d4f0e60bf06",
    "0xAFF95A7048bE933FDEC48658328249B38Ce82FAc",
    "0xe47A240317f06e3De9e963D4Ba98b438f60c657c",
    "0xE52f8c73c432578272E65a4E78c8B14452941E26",
    "0x3404Ac8c59f1C06C89a8979dBa25d48Cc6597C8B",
    "0xF8cC59f3af8d503d03B561C422A699967cCdAC3a",
    "0xD226c994c64a33e1Fc5eeFF840aD624D34967342",
    "0xB6a3F58B37B618DCde343C807D3BBC007dA5A1b3",
    "0x29d18f33ccB4DDB10B439B18E7Be8A02c7b12F16"];

  const handleAddReferral = async (e) => {
    e.preventDefault();
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      await contract.addRefferal(referralAddress, referralPercent, referralCode);
      alert('Referral added successfully');
    } catch (error) {
      console.error('Error adding referral:', error);
      alert('Failed to add referral. Please try again.');
    }
  };
  const oldContractAddress = "0x440Ba07f87Aa9E96141D6751B568874e85860eF3"; 
  const handleCheckReferrals = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
  const oldContract = new Contract(oldContractAddress, oldContractABI, signer);
    try {
      
      const referralData = [];
        for (const address of addressesToCheck) {
            console.log('Checking address:', address);
                const invitePercent = await oldContract.invitePercent(address);
                if (invitePercent > 0) {
                  console.log('Found referral:', address, invitePercent);
                  let inviteCode = "-";
                  referralData.push([address, inviteCode]);
                  
                  
                }
              }
      setReferralData(referralData);
      console.log('Referral Data:', referralData);
    } catch (error) {
      console.error('Error checking referrals:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleExportData = async () => {
    
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
  const oldContract = new Contract(oldContractAddress, oldContractABI, signer);
    try {
        console.log("Fetching data from contract...");
        const contract = new Contract(SETTINGS.collection, CollectionABI, signer);
        const totalSupply = await contract.totalSupply();
        console.log("Total supply:", totalSupply.toString());
        const tokenIds = [];
        const mintTimes = [];
        const lastClaims = [];
    
        for (let i = 1; i <= totalSupply; i++) {
            console.log("Checking owner of token:", i);
          tokenIds.push(i.toString());
          const mintTime = await oldContract.mintTime(i);
          mintTimes.push(mintTime.toString());
          const lastClaim = await oldContract.lastClaim(i);
          lastClaims.push(lastClaim.toString());
        }
    
        const data = {
          tokenIds,
          mintTimes,
          lastClaims
        };
    
      setExportedData(data);
      console.log('Exported Data:', data);
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchNFTs = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const nfts = await contract.getAllNFTsForUser(userAddress);
      setNfts(nfts);
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      alert('Failed to fetch NFTs. Please try again.');
    }
  };

  const fetchHolders = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.collection, CollectionABI, signer);
      const totalSupply = await contract.totalSupply();
      console.log('Total supply:', totalSupply);
      const holdersSet = new Set();

      for (let i = 1; i <= totalSupply; i++) {
        console.log('Checking owner of token:', i);
        const owner = await contract.ownerOf(i);
        holdersSet.add(owner);
      }

      setHolders([...holdersSet]);
    } catch (error) {
      console.error('Error fetching holders:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDailyDistribute = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      await contract.dailyDistribute();
      alert('Daily distribution executed successfully');
    } catch (error) {
      console.error('Error executing daily distribution:', error);
      alert('Failed to execute daily distribution. Please try again.');
    }
  };

  
  const LAST_REWARD_TIMESTAMP = 1721088000; // Replace with your last reward timestamp
  const ONE_DAY_SECONDS = 24 * 60 * 60;

  const fetchRewards = async () => {
    setLoadingRewards(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const rewards = [];
      const rewardsd = [];
      const rewardsr = [];
    for (let i = 0; i < 90; i++) {
      const dayTimestamp = LAST_REWARD_TIMESTAMP - (i * ONE_DAY_SECONDS);
      try {
        const reward = await contract.dailyNodeReward(dayTimestamp);
        console.log('Reward for timestamp', dayTimestamp, reward.toString());
        rewards.push({ dayTimestamp, reward: reward.toString() });
        if (reward.toString() != "0") {
            console.log("adding reward to array");
        rewardsd.push(dayTimestamp);
        rewardsr.push(reward.toString());
        }
      } catch (error) {
        console.error('Error fetching reward for timestamp', dayTimestamp, error);
      }
    }
    console.log('Rewards:', rewards);
    console.log('Rewardsd:', rewardsd);
    console.log('Rewardsr:', rewardsr);
    setRewards(rewards);
    } catch (error) {
      console.error('Error fetching rewards:', error);
    } finally {
      setLoadingRewards(false);
    }
  };

  const renderNFTs = () => {
    return nfts.map((nft, index) => (
      <Col key={index} sm={6} md={4} lg={3}>
        <NFTCard
          collectionAddress={nft.collectionAddress}
          tokenId={nft.tokenId}
          name={nft.name}
          imageURL={nft.imageURL}
        />
      </Col>
    ));
  };

  const renderReferrals = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Address</th>
            <th>Invite Code</th>
          </tr>
        </thead>
        <tbody>
          {referralData.map((data, index) => (
            <tr key={index}>
              <td>{data.address}</td>
              <td>{data.inviteCode}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const renderRewards = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Day</th>
            <th>Reward</th>
          </tr>
        </thead>
        <tbody>
          {rewards.map((reward, index) => (
            <tr key={index}>
              <td>{new Date(reward.day * 1000).toLocaleDateString()}</td>
              <td>{reward.amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    if (activeTab === 'viewNFTs') {
      fetchHolders();
    }
  }, [activeTab]);



  return (
    <Container className='pokemon-card'>
      <Nav variant="tabs" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
        <Nav.Item>
          <Nav.Link eventKey="addReferral">Add Referral</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="viewNFTs">View NFTs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="viewRewards">Daily rewards</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="exportData">Export Data</Nav.Link>
        </Nav.Item>
      </Nav>

      {activeTab === 'addReferral' && (
        <Row className="mt-4">
          <Col>
            <Form onSubmit={handleAddReferral}>
              <Form.Group controlId="formReferralAddress">
                <Form.Label>Referral Address</Form.Label>
                <Form.Control
                  type="text"
                  className='custom-select'
                  placeholder="Enter referral address"
                  value={referralAddress}
                  onChange={(e) => setReferralAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formReferralPercent">
                <Form.Label>Referral Percent</Form.Label>
                <Form.Control
                  type="number"
                  className='custom-select'
                  placeholder="Enter referral percent"
                  value={referralPercent}
                  onChange={(e) => setReferralPercent(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formReferralCode">
                <Form.Label>Referral Code</Form.Label>
                <Form.Control
                  type="text"
                  className='custom-select'
                  placeholder="Enter referral code"
                  value={referralCode}
                  onChange={(e) => setReferralCode(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Add Referral
              </Button>
            </Form>
          </Col>
        </Row>
      )}

      {activeTab === 'viewNFTs' && (
        <>
          <Row className="mt-4">
            <Col>
              <h3>View NFTs</h3>
              <Form onSubmit={(e) => { e.preventDefault(); handleFetchNFTs(); }}>
                <Form.Group controlId="formUserAddress">
                  <Form.Label>User Address</Form.Label>
                  <Form.Control
                    as="select"
                    value={userAddress}
                    onChange={(e) => setUserAddress(e.target.value)}
                  >
                    <option value="">Select an address</option>
                    {holders.map((holder, index) => (
                      <option key={index} value={holder}>
                        {holder}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Fetch NFTs'}
                </Button>
              </Form>
            </Col>
          </Row>
          <Row className="mt-4">{renderNFTs()}</Row>
        </>
      )}

      {activeTab === 'exportData' && (
        <Row className="mt-4">
          <Col>
            <h3>Export Data</h3>
            <Button variant="primary" onClick={handleExportData} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Export Data'}
            </Button>
            <Button variant="primary" onClick={handleCheckReferrals} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Check Referrals'}
            </Button>
            {exportedData && (
              <pre className="mt-4">
                {JSON.stringify(exportedData, null, 2)}
              </pre>
            )}

            {referralData.length > 0 && renderReferrals()}
          </Col>
        </Row>
      )}

      {activeTab === 'viewRewards' && (
        <Row className="mt-4">
          <Col>
          <div>
      <h1>Daily Node Rewards</h1>
      <button onClick={fetchRewards}>Fetch Rewards</button>
      <ul>
        {rewards.map(({ dayTimestamp, reward }) => (
          <li key={dayTimestamp}>
            {new Date(dayTimestamp * 1000).toLocaleDateString()}: {reward}
          </li>
        ))}
      </ul>
    </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default Admin;
