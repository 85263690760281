import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { ethers } from 'ethers';
import MainContractABI from '../abis/MainContractABI';
import NodesABI from '../abis/NodesABI';
import boxImg from '../images/box.png';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { useContract } from '../hooks/useContract';
import { BrowserProvider, Contract, formatUnits } from 'ethers';

const Node = ({ provider, account, collectionAddress, tokenId, unclaimedRewards, name, imageURL }) => {
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState('');

    const claimRewards = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();

        try {
            setLoading(true);
            setTxMessage("Caliming rewards...");

            // Connect to the NFT contract and claim rewards
            const nftContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );

            // Call the claimRewards function on the NFT contract
            const buyTx = await nftContract.claimRewards(tokenId);
            await buyTx.wait();

            setTxMessage("Rewards claimed!");
        } catch (error) {
            console.error("Error claiming rewards:", error);
            setTxMessage("Failed to claim rewards. Please try again.");
        } finally {
            setLoading(false);
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    useEffect(() => {
        // This can be used to load any initial data or perform a setup
    }, []);

    if (loading) {
        return (
            <div className="node-item">
                <div className="text-center">
                    <center><Spinner animation="border" role="status" /></center>
                </div>
            </div>
        );
    }

    return (
        <div className="node-item">
            <div className="node-image">
                <img src={boxImg} alt="box" />
            </div>
            <div className="node-info">
                <div className="node-id"># {tokenId}</div>
                <div className="node-name">{name}</div>
                <div className="node-rewards">Unclaimed: {unclaimedRewards}</div>
                <Button className='buton smallBtn d-none' onClick={claimRewards}>Claim</Button>
            </div>
        </div>
    );
};

export default Node;
